import Embla from "../Embla";
function Carousel() {
  return (
    <div id="carrusel">
      <div class="carru">
        <Embla></Embla>
      </div>
    </div>
  );
}

export default Carousel;
